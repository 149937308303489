import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const NotFoundPage = () => (
  <Wrapper>
    <h1>404 - Page Not Found</h1>
  </Wrapper>
);

export default NotFoundPage;
